import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Header: React.FC<{ scrollPosition: number }> = ({ scrollPosition }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setIsMenuOpen(false);
    }, [location]);

    const handleNavClick = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
        setIsMenuOpen(false);
    };

    const handleLogoClick = () => {
        navigate('/');
        setIsMenuOpen(false);
    };

    const isScrolled = scrollPosition > 0;

    return (
        <header className={`${isScrolled ? 'scrolled' : ''} ${isMenuOpen ? 'menu-open' : ''}`}>
            <div
                className="logo-container"
                onClick={handleLogoClick}
                style={{ cursor: 'pointer' }}
            >
                Aplo Inc.
            </div>
            <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={`nav-container ${isMenuOpen ? 'open' : ''}`}>
                <nav>
                    <Link to="/" className="nav-item" onClick={() => setIsMenuOpen(false)}>ABOUT</Link>
                    <Link to="/news" className="nav-item" onClick={() => setIsMenuOpen(false)}>NEWS</Link>
                    <Link to="/service" className="nav-item" onClick={() => setIsMenuOpen(false)}>SERVICE</Link>
                    <Link to="/contact" className="nav-item" onClick={() => setIsMenuOpen(false)}>CONTACT</Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;