import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

import Layout from './components/Layout';
import ThankYou from './components/ThankYou';
import Contact from './pages/Contact';
import News from './pages/News';
import Service from './pages/Service';
import Top from './pages/Top';
import Legal from './pages/Legal';
import PrivacyPolicy from './pages/PrivacyPolicy';

import './styles/index.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Top />,
      },
      {
        path: "/thank-you",
        element: <ThankYou />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/service",
        element: <Service />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/legal",
        element: <Legal />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <SpeedInsights />
    <Analytics />
  </React.StrictMode>
);