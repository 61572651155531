import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const News: React.FC = () => {
  const navigate = useNavigate();

  const newsItems = [
    {
      id: 1,
      image: "/training.png",
      alt: "AI研修プログラム",
      date: "2024年8月15日",
      category: "ニュース",
      title: "AI人材育成研修プログラムを発表",
      excerpt: "最新のAI技術を学べる人材育成プログラムを開始しました。",
    },
    {
      id: 2,
      image: "/chatbot.png",
      alt: "AIチャットボット",
      date: "2024年7月1日",
      category: "ニュース",
      title: "AIチャットボットサービスの提供を開始",
      excerpt: "カスタマイズ可能な高性能AIチャットボットの提供を開始しました。",
    },
    {
      id: 3,
      image: "/Aplo, Inc..png",
      alt: "会社設立",
      date: "2024年6月19日",
      category: "ニュース",
      title: "株式会社APLOを設立",
      excerpt: "AI技術を活用したソリューションを提供する株式会社APLOを設立しました。",
    },
  ];

  const handleBackToTop = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="main-content">
      <div id="news-wrapper" className="section-wrapper">
        <section id="news" className="section">
          <div className="section-content">
            <h1 className="section-title">ニュース一覧</h1>
            <div className="news-list">
              {newsItems.map((item) => (
                <div key={item.id} className="news-item">
                  <div className="news-image">
                    <img src={item.image} alt={item.alt} />
                  </div>
                  <div className="news-content">
                    <div className="news-meta">
                      <p className="news-date">{item.date}</p>
                      <span className={`news-category ${item.category.toLowerCase()}`}>{item.category}</span>
                    </div>
                    <h3 className="news-title">{item.title}</h3>
                    <p className="news-excerpt">{item.excerpt}</p>
                  </div>
                </div>
              ))}
            </div>
            <button onClick={handleBackToTop} className="news-more-button">トップページへ戻る</button>
          </div>
        </section>
      </div>
    </main>
  );
};

export default News;