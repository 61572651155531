import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <Link to="/legal"  onClick={() => {
            window.scrollTo(0, 0);
          }} className="footer-link">特定商取引法に基づく表記</Link>
          <Link to="/privacy-policy" onClick={() => {
            window.scrollTo(0, 0);
          }} className="footer-link">プライバシーポリシー</Link>
        </div>
        <div className="footer-copyright">
          © {new Date().getFullYear()} Aplo Inc. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;