import React from 'react';
import Contact from '../components/Contact';

const ContactPage: React.FC = () => {
  return (
    <main className="main-content">
            <Contact />
    </main>
  );
};

export default ContactPage;