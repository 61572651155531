import React from 'react';
import '../styles/LegalNotice.css';

function LegalNotice() {
  return (
    <main className="main-content">
      <section className="legal-section">
        <div className="section-content">
          <h1 className="section-title">特定商取引法に基づく表記</h1>
          <div className="legal-content">
            <table className="legal-table">
              <tbody>
                <tr>
                  <th>事業者名</th>
                  <td>株式会社APLO</td>
                </tr>
                <tr>
                  <th>代表者名</th>
                  <td>代表取締役 中村 浩之</td>
                </tr>
                <tr>
                  <th>所在地</th>
                  <td>〒150-0001 東京都渋谷区神宮前6-23-4 桑野ビル2階</td>
                </tr>
                <tr>
                  <th>電話番号</th>
                  <td>お問い合わせはメールよりお願い致します</td>
                </tr>
                <tr>
                  <th>メールアドレス</th>
                  <td>contact@aplo.jp</td>
                </tr>
                <tr>
                  <th>料金</th>
                  <td>各サービスの料金はサービスページをご確認ください</td>
                </tr>
                <tr>
                  <th>支払方法</th>
                  <td>銀行振込、クレジットカード</td>
                </tr>
                <tr>
                  <th>提供時期</th>
                  <td>契約成立後、速やかにサービスを提供いたします</td>
                </tr>
                <tr>
                  <th>解約</th>
                  <td>解約をご希望される場合にはお問い合わせください</td>
                </tr>
                <tr>
                  <th>返品</th>
                  <td>サービスの性質上、返品は承っておりません</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </main>
  );
}

export default LegalNotice;