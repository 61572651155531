import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ThankYou.css';

const ThankYou: React.FC = () => {
  return (
    <main className="main-content">
      <div id="thank-you-wrapper" className="section-wrapper">
        <section id="thank-you" className="section">
          <div className="section-content thank-you-content">
            <h1 className="section-title">お問い合わせ完了</h1>
            <div className="thank-you-message">
              <p>お問い合わせいただき、ありがとうございます。</p>
              <p>近日中に担当者よりご連絡させていただきます。</p>
            </div>
            <div className="divider"></div>
            <p className="company-name">Aplo Inc.</p>
            <Link to="/" className="news-more-button">トップページへ戻る</Link>
          </div>
        </section>
      </div>
    </main>
  );
};

export default ThankYou;