import React from 'react';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">プライバシーポリシー</h1>
      <div className="privacy-policy-content">
        <p>
          当社は、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。
        </p>
        <section className="privacy-policy-section">
          <h2>1. 個人情報の管理</h2>
          <p>当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。</p>
        </section>
        <section className="privacy-policy-section">
          <h2>2. 個人情報の利用目的</h2>
          <p>お客さまからお預かりした個人情報は、当社からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料のご送付に利用いたします。</p>
        </section>
        <section className="privacy-policy-section">
          <h2>3. 個人情報の第三者への開示・提供の禁止</h2>
          <p>当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</p>
          <ul>
            <li>お客さまの同意がある場合</li>
            <li>お客さまが希望されるサービスを行なうために当社が業務を委託する業者に対して開示する場合</li>
            <li>法令に基づき開示することが必要である場合</li>
          </ul>
        </section>
        <section className="privacy-policy-section">
          <h2>4. 個人情報の安全対策</h2>
          <p>当社は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</p>
        </section>
        <section className="privacy-policy-section">
          <h2>5. ご本人の照会</h2>
          <p>お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。</p>
        </section>
        <section className="privacy-policy-section">
          <h2>6. 法令、規範の遵守と見直し</h2>
          <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</p>
        </section>
        <p className="policy-date">2024年6月19日制定</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;