import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Contact.css';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    company: '',
    name: '',
    email: '',
    phone: '',
    title: '',
    message: '',
  });
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('送信に失敗しました');
      }

      const result = await response.json();
      console.log('送信結果:', result);
      console.log('フォームデータ:', formData);
      
      navigate('/thank-you');
    } catch (error) {
      console.error('送信エラー:', error);
      // エラー処理（例：エラーメッセージの表示）
    }
  };

  return (
    <section className="contact">
      <div className="section-contact">
        <h2 className="contact-title">お問い合わせ</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="input-container">
              <input type="text" name="company" id="company" onChange={handleChange} value={formData.company} />
              <label htmlFor="company">会社名</label>
            </div>
          </div>
          <div className="form-group">
            <div className="input-container">
              <input type="text" name="name" id="name" required onChange={handleChange} value={formData.name} />
              <label htmlFor="name">お名前 <span className="required">*</span></label>
            </div>
          </div>
          <div className="form-group">
            <div className="input-container">
              <input type="email" name="email" id="email" required onChange={handleChange} value={formData.email} />
              <label htmlFor="email">メールアドレス <span className="required">*</span></label>
            </div>
          </div>
          <div className="form-group">
            <div className="input-container">
              <input type="tel" name="phone" id="phone" onChange={handleChange} value={formData.phone} />
              <label htmlFor="phone">電話番号</label>
            </div>
          </div>
          <div className="form-group">
            <div className="input-container">
              <input type="text" name="title" id="title" required onChange={handleChange} value={formData.title} />
              <label htmlFor="title">タイトル <span className="required">*</span></label>
            </div>
          </div>
          <div className="form-group">
            <div className="input-container">
              <textarea name="message" id="message" required onChange={handleChange} value={formData.message}></textarea>
              <label htmlFor="message">お問合せ内容 <span className="required">*</span></label>
            </div>
          </div>
          <button type="submit">送信する</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
